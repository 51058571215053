@mixin custom-mat-progress-bar-theme($theme) {
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
  mat-progress-bar.progress-bar .mat-progress-bar-buffer {
    background: $white;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version. */
  .mat-primary .mat-progress-bar-buffer {
    background-color: $custom-offblue-diamond;
  }
}

@import '@oculus/styling/ellipse/colors';

@mixin custom-mat-expansion-theme($theme) {
  mat-expansion-panel {
    box-shadow: none !important;
    border-radius: 0px !important;

    mat-expansion-panel-header {
      background: #f2f2f2 !important;
    }

    .mat-expansion-panel-body {
      padding: 16px 24px !important;
    }
  }

  .custom-menu {
    border: 0 !important;
    box-shadow: none !important;

    .mat-expansion-panel-header {
      padding: 0 15px;
      background: none !important;
    }

    .mat-expansion-panel-body {
      padding: 0 !important;

      button {
        padding-left: 32px;
      }
    }
  }

  mat-expansion-panel.no-background {
    &.mat-expansion-panel-spacing {
      margin: 0px !important;
    }

    .mat-expansion-panel-header {
      background: transparent !important;
      height: 42px !important;

      span.mat-content {
        mat-panel-title {
          font-size: 14px !important;
        }

        mat-panel-description {
          font-size: 12px !important;
        }
      }

      &::before {
        font-family: 'Material Icons';
        content: '\e5cc';
        font-weight: bolder;
        display: inline-block;
        width: 1.5em;
        font-size: 18px;
        margin-left: -1em;
        color: map-get($grays, 600) !important;
      }

      &.mat-expanded {
        &::before {
          content: '\e313' !important;
        }
      }
    }

    .mat-expansion-panel-body {
      padding: 0px 32px 0px 48px !important;
    }
  }

  mat-expansion-panel.warning-panel {
    &.mat-expansion-panel-spacing {
      margin: 0px !important;
    }

    .mat-expansion-panel-header {
      height: 42px !important;
      background-color: rgba($orange, 0.075);

      span.mat-content mat-panel-title {
        mat-icon {
          $icon-size: 20px;
          width: $icon-size !important;
          height: $icon-size !important;
          font-size: $icon-size !important;
          line-height: $icon-size !important;
          color: $orange;
        }
      }
    }

    .mat-expansion-panel-content {
      max-height: 150px;
      overflow: auto;
    }
    .mat-expansion-panel-body {
      padding: 4px 8px 4px 32px !important;
      font-size: 12px !important;
    }
  }
}

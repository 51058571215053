@import '@oculus/styling/ellipse/colors';

@mixin sso-tooltip-theme($theme) {
  .tippy-box[data-theme~='custom'] {
    background-color: #616161;
  }

  .tippy-box[data-theme~='custom'][data-placement^='bottom-start']
    > .tippy-arrow::before {
    border-bottom-color: #616161;
  }

  .tippy-box[data-theme~='transparent'] {
    background-color: transparent;
  }

  .tippy-box[data-theme~='transparent'][data-placement^='right']
    > .tippy-arrow::before {
    border-bottom-color: transparent;
  }

  .tippy-box[data-theme~='dark-blue'] {
    background-color: $blue-bigstone;
  }

  sso-tooltip-anchor mat-icon {
    cursor: help;
    margin-top: 7px !important;
    color: $tooltip-anchor-color;
  }

  sso-tooltip-anchor.attached-component mat-icon {
    margin-top: 3.5px !important;
    font-size: 18px !important;
  }

  sso-tooltip-anchor mat-icon {
    cursor: help;
    margin-top: 7px !important;
    color: $tooltip-anchor-color;
  }

  sso-tooltip-anchor.attached-component mat-icon {
    margin-top: 3.5px !important;
    font-size: 18px !important;
  }
}

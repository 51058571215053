@mixin sso-charts-theme($theme) {
  // pie-chart
  .chart-legend .legend-labels {
    background: white !important;
    white-space: normal !important;
    width: 180px !important;
  }

  .chart-legend .legend-title {
    text-align: center !important;
  }

  .sso-chart-tooltip {
    padding: 1em 0.5em;
    width: 100% !important;
  }

  .sso-chart-tooltip-title {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    text-align: center;
  }

  .sso-chart-tooltip-subtitle {
    margin: 0.5em;
    font-size: 1.25rem;
    font-weight: bold;
  }

  sso-waterfall-component, sso-pie-chart-component {
    .chart-legend li span{
      display: inline-block !important;
      width: 12px !important; 
      height: 12px !important;
      margin-right: 5px !important;
      border-radius: 2.5px;
      
    }
  
    .chart-legend{
      height:250px !important;
      overflow:auto !important;
      width: auto !important;
      }
      
    .chart-legend li{
      cursor:pointer !important;
      text-align: initial;
      color: #afb7c8;
      font-size: 90%;
    }
    
    .strike{
        text-decoration: line-through !important;
    }
  }

}

@import '@oculus/styling/ellipse/colors';

.sso-maximize-width {
  width: 100%;
}

.sso-spacer {
  margin-bottom: 20px !important;
}

.sso-spacer-top-half {
  margin-top: 10px !important;
}

.sso-clickable {
  cursor: pointer;
}

.sso-remove-margin {
  margin: 0 !important;
}

.sso-table-message {
  margin-left: 20px;
}

.align-start {
  text-align: start;
  * {
    text-align: start;
  }
}

.align-end {
  text-align: end;
  * {
    text-align: end;
  }
}

.marginless-header {
  margin-bottom: 0 !important;
}

.sso-text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sso-text-small {
  font-size: small !important;
}

.sso-text-smaller {
  font-size: smaller !important;
}

.sso-text-gray {
  color: map-get($grays, 600) !important;
}

.sso-text-blue {
  color: $blue-corporate !important;
}

.sso-text-large {
  font-size: large !important;
}

.sso-text-bold {
  font-weight: bold;
}

.sso-color-java {
  color: var(--nj-color-base-green-java) !important;
}

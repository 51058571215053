@mixin sso-kpi-theme($theme) {
  .kpi-details {
    div.kpi-value {
      font-size: medium;
      font-weight: bold;
      margin-bottom: -4px;
    }

    div.kpi-unit {
      font-size: x-small;
      color: rgba(0, 0, 0, 0.4);
      margin-bottom: -4px;
    }
  }

  .kpi-percentage {
    font-size: x-small;

    mat-icon {
      width: 12px !important;
      height: 12px !important;
      font-size: small !important;
    }
  }
}

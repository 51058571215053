/* Theming map for extracting colors */
$primary: map-get($ssotool-theme, primary);
$accent: map-get($ssotool-theme, accent);
$warn: map-get($ssotool-theme, warn);
$foreground: map-get($ssotool-theme, foreground);
$background: map-get($ssotool-theme, background);

/* Colors to be used in your styles */
// Primary-based colors
$dark-blue: mat.get-color-from-palette($primary, darker);
$blue: mat.get-color-from-palette($primary);
$blue-bolt: #007acd;
$light-blue: mat.get-color-from-palette($primary, lighter);

// Accent-based colors
$green: mat.get-color-from-palette($accent, darker);
$orange: mat.get-color-from-palette($accent);
$yellow: mat.get-color-from-palette($accent, lighter);

// Warn-based colors
$dark-red: mat.get-color-from-palette($warn, darker);
$red: mat.get-color-from-palette($warn);
$light-red: mat.get-color-from-palette($warn, lighter);

// Background-based colors
$background-color: mat.get-color-from-palette($background, background);
$hover-color: mat.get-color-from-palette($background, hover);
$hint-color: mat.get-color-from-palette($foreground, hint-text);
$label-color: mat.get-color-from-palette($foreground, label);
$disabled-text: mat.get-color-from-palette($foreground, disabled-text);
$disabled-color: mat.get-color-from-palette($background, unselected-chip);
$light-primary-text: black;

// Foreground-based colors
$text-color: mat.get-color-from-palette($foreground, text);
$light-gray: mat.get-color-from-palette($foreground, contrast);
$gray: mat.get-color-from-palette($foreground, secondary-text);
$foreground-divider-color: mat.get-color-from-palette($foreground, divider);
$icon-color: mat.get-color-from-palette($foreground, icon);

/* Others */
// TODO The following should be changed and reference the extracted theme palette
$white: #fff;
$black: #000;
$dirty-white: #f5f5f5;
$tooltip-anchor-color: rgba(0, 0, 0, 0.12) !important;
$smoke-gray: #5b646b;
$dark-shade-blue: #005288;

// Custom Colors
$custom-deep-purple: #9575cd;
$custom-light-blue: #4fc3f7;
$custom-green: #81c784;
$custom-red: #e57373;
$custom-orange: #ffb74d;
$custom-offblue-diamond: #c2ebff;

// Opacity
$opacity-default: 0.8;

// Transitions
$transition-seconds: 0.2s;
$transition-effect: cubic-bezier(0.35, 0, 0.25, 1);
$transition-opacity: opacity $transition-seconds $transition-effect;
$transition-bg: background-color $transition-seconds $transition-effect;
$transition-all: all $transition-seconds $transition-effect;

/** Fluid 5 **/
/* Brand */
$blue-logo: #00aaff;
$gradient-color: #23d2b5;
$gradient: linear-gradient(135deg, $blue-logo 0.89%, $gradient-color 94.05%);

/* Main Color */
$blue-bolt: #007acd;

/* Functional Colors */
$success: #418448;
$warning: #ff8c47;
$danger: #db3835;

/* Neutral Colors */
$white: #ffffff;
$gray-100: #f3f5f6;
$gray-200: #e6eaed;
$gray-300: #c5ced5;
$gray-400: #9dadb8;
$gray-500: #768c9b;
$gray-600: #607a8c;
$gray-700: #435c6e;
$gray-800: #314452;
$gray-900: #1f2c34;
$black: #192229;

/* Additional Color */
$purple-lavender: #6d4797;
$space-ultramarine: #17255f;
$french-pink: #f1468d;
$teal-green: #0a9994;
$saffron-yellow: #f2c665;

/* Data Visualization Colors */
$lighter-yellow: #ffeaa1;
$lighter-orange: #ffbf8e;
$lighter-red: #ffbdb0;
$lighter-pink: #ffb8d6;
$lighter-purple: #d6c6e9;
$lighter-blue: #b6cdf8;
$lighter-ultramarine: #c4cbe5;
$lighter-teal: #88dad8;
$lighter-green: #a2daa3;

$darker-yellow: #a4853e;
$darker-orange: #bd5a21;
$darker-red: #aa2424;
$darker-pink: #81475f;
$darker-purple: #4d3a62;
$darker-blue: #005aa0;
$darker-ultramarine: #44549b;
$darker-teal: #0a6361;
$darker-green: #316336;

/* GBU Colors */
$energy-solutions: #e18554;
$network: #278cbc;
$thermal-supply: #4bb0b9;
$renewable: #67ae6e;

// TODO: Update colors from oculus
$hover: #f3f5f6;

@mixin sso-breadcrumbs-theme($theme) {
  .breadcrumbs {
    :not(:last-child, mat-icon) {
      text-transform: capitalize;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    mat-icon {
      cursor: default;
    }

    :last-child {
      pointer-events: none;
      font-weight: bold;
    }
  }

  .bold-breadcrumb {
    .breadcrumb {
      font-weight: 525;
    }

    .breadcrumb-divider {
      font-weight: 525;
    }
  }
}

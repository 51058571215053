@use "sass:map";
@import '@oculus/styling/ellipse';

@mixin custom-mat-icon-theme($theme) {
  .mat-icon.large {
    height: 300px;
    width: 300px;
    font-size: 300px;
  }

  // Apply washout color to tooltip anchor in input
  .mat-icon.input-tooltip-anchor {
    cursor: help !important;
    color: $tooltip-anchor-color;
    font-size: 18px !important;
    height: 18px !important;
    width: 18px !important;
    display: inline !important;
  }

  .mat-icon.prefix-anchor {
    color: $tooltip-anchor-color;
    font-size: 18px !important;
    height: 18px !important;
    width: 18px !important;
    display: inline !important;
  }

  .mat-icon.suffix-anchor {
    color: map.get($grays, 900);
    font-size: 18px !important;
    height: 18px !important;
    width: 18px !important;
    display: inline !important;
  }

  .ongoing-process {
    color: $yellow;

    mat-icon {
      font-size: 18px !important;
      height: 18px !important;
      width: 18px !important;
      display: inline !important;
    }
  }

  .gray-icon {
    color: rgba(0, 0, 0, 0.54);
  }

  .success-icon {
    color: $green !important;
  }

  .warn-icon {
    color: var(--nj-color-base-danger) !important;
  }

  .inactive-icon {
    color: var(--nj-color-base-disabled) !important;
  }
}

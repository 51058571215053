@mixin sso-efficiency-matrix-theme($theme) {
  sso-fluids-toggle-group {
    $selected-color: $blue;

    .delete-button {
      opacity: 0;
      transition: $transition-all;
      &.visible {
        opacity: 1;
        transition: $transition-all;
      }
    }

    h4,
    h3 {
      margin-bottom: 0 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    .mat-button-toggle-group {
      border: none !important;
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle,
      .mat-button-toggle-ripple {
        border-radius: 10px !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-label-content {
        line-height: 36px !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle {
        border: 2px solid transparent !important;
        color: $black !important;

        .mat-icon {
          stroke: $black !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        &:hover:not(.mat-button-toggle-checked) {
          background-color: $hover-color !important;
          border-color: $gray !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        &.mat-button-toggle-checked::before {
          color: $selected-color !important;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
        &.mat-button-toggle-checked {
          border-color: $selected-color !important;
          color: $selected-color !important;
          background: none !important;
          background-image: linear-gradient(
            -45deg,
            rgba($selected-color, 0.08),
            rgba($selected-color, 0.03),
            rgba($selected-color, 0.08)
          ) !important;

          &:hover {
            background-color: transparent !important;
          }

          .mat-icon {
            stroke: $selected-color !important;
          }
        }

        h4 {
          font-size: 14px !important;
        }
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-focus-overlay {
        border-bottom: 0 !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
      .mat-button-toggle-checked {
        &::before {
          content: 'MAIN';
          position: absolute;
          left: 5px;
          top: 0;
          font-size: 10px;
          pointer-events: none;
        }
      }
    }
  }
}

@use "sass:map";
@import '@oculus/styling/ellipse';

@mixin custom-mat-tabs-theme($theme) {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-active {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    .mat-tab-label-content {
      font-weight: bold;
      color: black;

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        background: $blue-corporate;
        right: 0;
        bottom: 0;
      }
    }
  }

  mat-ink-bar {
    display: none !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-active {
    .mat-icon {
      svg[fill='none'] {
        path {
          stroke: $blue-corporate;
        }
      }
    }
  }

  sso-tab-group {
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
    mat-tab-group {
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      .mat-tab-label {
        opacity: 1 !important;
        color: #707070;
      }
    }
  }

  .multi-level-tabs {
    sso-tab-group {
      &.tabs-primary {
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        mat-tab-group {
          background-color: rgba(231, 231, 232, 0.1);
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          .mat-tab-label-active {
            background-color: rgba(231, 231, 232, 0.3);
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            .mat-tab-label-content {
              font-weight: bold;
              color: $blue-corporate !important;

              &:after {
                height: 0px !important;
              }
            }
          }
        }
      }

      &.tabs-secondary {
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        mat-tab-group {
          background-color: rgba(231, 231, 232, 0.3);
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          .mat-tab-label-active {
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            .mat-tab-label-content {
              font-weight: normal !important;
            }
          }
        }
      }
    }

    sso-tab-group:only-child {
      &.tabs-primary {
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        mat-tab-group {
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          .mat-tab-label-active {
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            .mat-tab-label-content {
              &:after {
                height: 3px !important;
              }
            }
          }
        }
      }
    }
  }

  .multi-gradient-tabs {
    sso-tab-group {
      border-bottom: 1px solid #c5ced5;

      &.tabs-primary {
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        mat-tab-group {
          background-color: rgba(231, 231, 232, 0.1);
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          .mat-tab-label {
            min-width: 50%;
          }
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          .mat-tab-label-active {
            background-color: rgba(231, 231, 232, 0.3);
          }
        }
      }

      &.tabs-secondary {
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
        mat-tab-group {
          .mat-icon svg path {
            stroke: transparent;
          }
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          .mat-tab-labels > div:not(.mat-tab-label-active) {
            .mat-icon svg path {
              fill: map.get($grays, 400);
            }
          }
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
          .mat-tab-label-active {
            background-color: rgba($blue-corporate, 0.04);
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
            .mat-tab-label-content {
              span {
                font-weight: bold !important;
                @include ellipse-typography-gradient-font();
              }

              &:after {
                height: 0px !important;
              }
            }
          }
        }
      }
    }
  }
}

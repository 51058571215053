@use 'sass:map';
@import '@oculus/styling/ellipse';

@mixin custom-mat-select-theme($theme) {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-arrow-wrapper {
    padding-top: 7px;
  }

  .mat-mdc-select-panel.custom-panel {
    overflow: hidden !important;
    min-height: 150px;
    max-height: 400px;
    min-width: 150%;

    .panel-content {
      overflow-x: hidden;
      overflow-y: auto;
      height: 180px !important;
    }
  }

  .mat-mdc-select-panel {
    .simple-selection-content {
      overflow-x: hidden;
      overflow-y: auto;
      height: 180px !important;
    }
  }

  sso-multi-select.menu-mode {
    visibility: hidden;

    .mat-mdc-form-field {
      width: 100%;
    }
  }

  .fit-selection-panel.mat-mdc-select-panel {
    mat-option {
      width: 100%;
      box-sizing: border-box;
    }
  }

  sso-select.colored-dropdown {
    // .mdc-text-field {
    //   border-radius: 25px;
    //   background-color: #0b74c0;
    // }

    .mat-mdc-form-field.mat-focused {
      outline: none;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    span,
    .mat-select-arrow {
      color: white !important;
      text-transform: uppercase;
    }

    .mat-form-field-appearance-outline {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-outline {
        display: none;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        padding-bottom: 6px !important;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-arrow-wrapper {
        padding-top: 8px !important;
      }
    }

    // color mapping
    .mat-mdc-form-field.blue {
      .mdc-text-field {
        background-color: #0b74c0;
      }
    }

    .mat-mdc-form-field.green {
      .mdc-text-field {
        background-color: #69af23;
      }
    }

    .mat-mdc-form-field.yellow {
      .mdc-text-field {
        background-color: #e6b530;
      }
    }
  }

  .small-select {
    .mat-mdc-form-field.mat-form-field-appearance-outline {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        padding-top: 3px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        height: 18px;
        width: 140px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-arrow-wrapper {
        padding-top: 13px;
      }
    }
  }

  .medium-select {
    .mat-mdc-form-field.mat-form-field-appearance-outline {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        padding-top: 3px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        height: 20px;
        width: 200px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-arrow-wrapper {
        padding-top: 13px;
      }
    }
  }

  .centered-equal-select {
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    mat-select {
      display: flex;
      justify-content: center;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value {
      max-width: 100%;
    }

    .mat-mdc-form-field {
      font-weight: 525;
    }

    .mat-mdc-form-field.mat-form-field-appearance-outline {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        padding-right: 0px !important;
        height: 25px;
        width: 140px;
      }
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-arrow-wrapper {
        padding-top: 13px;
        padding-left: 5px;
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-select-arrow {
          border-left: 7px solid transparent;
          border-right: 7px solid transparent;
          border-top: 7px solid;
        }
      }
    }
  }
}

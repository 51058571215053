@import 'dependencies/helpers.style.scss';
@import '@oculus/styling/ellipse';

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  overflow: hidden;
  padding: 0;
  background: white;
}

p {
  line-height: 30px;
  margin-bottom: 25px !important;
}

.dataset {
  ul li {
    margin-bottom: 15px;
  }
}

table {
  width: 100%;
}

/* Hide spinner in number input */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

/* Workaround to hidden directive issue conflicting css when host display is block */
[hidden] {
  display: none !important;
}

/* Start - PR-820 Space Optimization */
h2,
p {
  margin-bottom: 10px !important;
}

p {
  line-height: 24px !important;
}

input[type='number'].hide-spinner::-webkit-inner-spin-button,
input[type='number'].hide-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  line-height: 20px;
}
/* End - PR-820 Space Optimization */

.oculus-drawer-content {
  background: white;
  min-height: 100vh;
}

.sso-page {
  background: white;
  padding: 1em 3em;
}

.sso-sectioned-page {
  background: white;
  height: 90vh;
  padding: 0rem 5rem 3rem 5rem;
}

.sso-page-title {
  font-weight: bolder;
  padding-bottom: 10px;
}

.sso-card {
  @include mat.elevation(2);
  padding: 1em;
  background: white;
  border-radius: 0.5em;
}

.sso-table-container {
  overflow: auto;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0.5em;
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  mat-table {
    width: 100%;
  }
}

.sso-link {
  cursor: pointer;
  color: #009de9;
  font-weight: 600;
}

.fill-height-to-page {
  height: 80vh;
}

.full-height {
  height: 100%;
}

.three-fourths-height {
  height: 75%;
}

.three-fourths-width {
  width: 75%;
}

.full-width {
  width: 100% !important;
}

.pointer-events-none {
  pointer-events: none;
}

.ease-on-interact {
  transition: ease 0.3s;
  opacity: 0.5;

  &:hover,
  &:focus,
  &:active {
    transition: ease 0.3s;
    opacity: 1;
  }
}

.icon-rotate {
  transform: rotate(90deg);
}

.full-opacity {
  opacity: 1;
}

.overflow-scroll {
  overflow: scroll;
}

.value-header {
  display: flex;
  justify-content: flex-end;
}

.value-item {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

// chrome performance when opening dialog is slow, fixed it with this
.mat-mdc-dialog-container {
  will-change: transform, opacity;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #009de9;
  animation: spinner 0.8s linear infinite;
}

.table-container {
  display: block;
  background-color: white;
  min-height: fit-content;
}

mat-sidenav-content {
  background: white;
}

.oculus-header-container {
  background: white !important;
}

.oculus-drawer-logo {
  padding: 12px 16px !important;
  padding-top: 24px !important;
}

.mat-mdc-tooltip {
  font-size: smaller !important;
  white-space: pre-line !important;
}

.sso-plain-table {
  .mat-mdc-header-row,
  .mat-mdc-row {
    border-style: none !important;
    background: white !important;
    z-index: 1 !important;
  }
}

.sso-stripe-table {
  .mat-mdc-header-row,
  .mat-mdc-row {
    border-style: none !important;
  }

  .mat-mdc-header-row {
    background: #f5f5f5 !important;
    z-index: 1 !important;
  }

  .mat-mdc-row {
    &:nth-child(odd) {
      background: #fafafa !important;
    }

    &:nth-child(even) {
      background: white !important;
    }
  }
}

.sso-reverse-stripe-table {
  .mat-mdc-header-row,
  .mat-mdc-row {
    border-style: none !important;
  }

  .mat-mdc-header-row {
    background: white !important;
    z-index: 1 !important;
  }

  .mat-mdc-row {
    &:nth-child(odd) {
      background: white !important;
    }

    &:nth-child(even) {
      background: #fafafa !important;
    }
  }
}

.sso-reverse-expanded-stripe-table {
  .mat-mdc-header-row,
  .mat-mdc-row {
    border-style: none !important;
    background: white !important;
  }

  .mat-mdc-header-row {
    z-index: 1 !important;
  }

  .mat-mdc-row {
    &:nth-child(4n + 2) {
      background: #fafafa !important;
    }

    &:nth-child(4n + 3) {
      background: #fafafa !important;
    }
  }
}

sso-title-bar {
  width: 100%;
  margin-bottom: 20px;
}

button {
  b {
    padding-left: 4px;
  }
}

.pointer-none {
  pointer-events: none !important;
}

.graph-no-data-container {
  position: absolute;
  width: 100%;
  height: 90%;
  color: rgba(0, 0, 0, 0.3);
  font-size: x-large;
  font-style: italic;
}

.x-small-text {
  font-size: x-small;
}

.rangeslider-slidebox {
  cursor: move;
  cursor: grab;
}

.dragcover {
  cursor: grabbing;
}

.divider {
  height: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
}

.toast-message {
  margin-top: 1px;
  font-weight: bolder;
}

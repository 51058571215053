@mixin sso-yearly-input-theme($theme) {
  sso-yearly-input {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-legacy.mat-form-field-disabled
      .mat-form-field-underline {
      background-image: none !important;
      background-color: rgba(0, 0, 0, 0.42) !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    .mat-form-field-appearance-legacy.multi-value
      .mat-form-field-wrapper
      .mat-form-field-flex
      .mat-form-field-infix {
      padding-bottom: 0.15em;
    }
  }
}

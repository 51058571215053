@mixin general-info-message-theme($theme) {
  .info-message {
    text-align: center;
    padding: 10px;
    width: 100%;
    background-color: rgba($custom-light-blue, 0.075);

    mat-icon {
      color: $custom-light-blue;
    }

    span {
      position: relative;
    }
  }
}

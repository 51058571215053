@import '@oculus/styling/ellipse/colors';

@mixin custom-mat-list-theme($theme) {
  $border-color: map-get($grays, 300);
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  mat-list-item.active {
    background-color: rgba($blue-allports, 0.15) !important;
    box-shadow: inset 0px 0px 0px 1px $border-color;

    &.left-item {
      box-shadow: inset 1px 0px 0px 1px $border-color !important;
    }

    &.right-item {
      box-shadow: inset -1px 0px 0px 1px $border-color !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
  mat-list-item.resultsonly {
    background-color: rgba(128, 128, 128, 0.3) !important;
  }
}

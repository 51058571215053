@mixin custom-mat-table-theme($theme) {
  .mat-mdc-table {
    a {
      color: $blue;
      text-decoration: none;
    }

    .mat-mdc-row {
      transition: $transition-bg;
      &:nth-child(even) {
        background: $light-gray;
      }
      &:hover {
        background: $hover-color;
      }
    }

    .mat-mdc-header-cell {
      font-size: 14px;
      font-weight: bold;
    }

    .mat-mdc-header-cell mat-icon {
      margin-top: 9px !important;
    }

    .mat-mdc-cell {
      font-size: 14px;
      font-weight: normal;
      div.list-reference {
        overflow: hidden;
        text-overflow: ellipsis !important;
        white-space: nowrap;
      }

      div.text-overflow {
        overflow: hidden;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;
      }
      .error {
        color: $red;
      }
      .success {
        color: $green;
      }
    }
    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
    mat-chip {
      pointer-events: none;
    }
  }
}

.permission-select {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  .mat-form-field-wrapper {
    padding-bottom: 0.2em !important;
    padding-top: 0.1em !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-value {
    padding-bottom: 0.3em;
  }
}

sso-details-base,
sso-detailed-compare {
  .mat-column-type {
    $icon-size: 13px !important;

    flex: 0 0 37px !important;
    width: 37px !important;
    mat-icon {
      height: $icon-size;
      width: $icon-size;
      font-size: $icon-size;
      line-height: $icon-size;
    }
  }
}

.mat-mdc-table.no-row-divider {
  mat-row {
    .mat-mdc-cell {
      border-bottom-style: none;
      border: 0px;
    }
  }

  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      border-bottom-style: none;
    }
  }
}

sso-campaign-tablem,
.sso-stripe-table {
  mat-row {
    .mat-mdc-cell {
      border-bottom-style: none;
      border: 0px;
    }
  }
  .mat-mdc-header-row {
    .mat-mdc-header-cell {
      border-bottom-style: none;
    }
    z-index: 2 !important;
  }
}

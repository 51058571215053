@import '@oculus/styling/ellipse/colors';

@mixin custom-mat-form-field-theme($theme) {
  // Special form field to only show error message or hints; very useful to non-material inputs such as tables, etc.
  .mat-mdc-form-field.hidden {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      height: 0;
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-flex,
      .mat-form-field-infix,
      .mat-form-field-underline {
        height: 0;
        visibility: hidden;
      }
    }
  }

  /* Start - PR-820 Space Optimization */
  .mat-mdc-form-field.mat-form-field-appearance-outline {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
      padding: 12px 0.5em 12px !important;
      margin-top: -5px !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-should-float {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label-wrapper {
      margin-top: 5px !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field.mat-form-field-appearance-outline.mat-form-field-invalid
    .mat-form-field-wrapper {
    padding-bottom: 1.34375em !important;
  }

  .editable-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      padding-bottom: 0px !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      padding: 0px !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
      padding: 5px !important;
      border: 0;
    }

    mat-error {
      font-size: xx-small !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-subscript-wrapper {
      padding: 0px !important;
      margin: 5px;
      position: static;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      position: static;
    }
  }

  .big-font-field {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-infix {
      font-size: 20px !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .disabled-gray .mat-form-field-disabled .mat-form-field-wrapper {
    background-color: map-get($grays, 200) !important;
  }

  .individual-error {
    font-style: italic;
    font-size: smaller;
  }

  sso-search-input.long-width .mat-mdc-form-field {
    width: 100%;
  }

  sso-multi-select.no-error-container
    .mat-mdc-form-field.mat-form-field-invalid
    .mat-form-field-wrapper,
  .no-error-container.mat-form-field.mat-form-field-invalid
    .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }

  .sso-yearly-input-custom {
    .mdc-text-field--disabled {
      pointer-events: all !important;
    }

    .mat-mdc-form-field-infix {
      display: flex;
    }
  }
}

@mixin custom-mat-snackbar-theme($theme) {
  .mat-mdc-snack-bar-container {
    .mat-mdc-snack-bar-action,
    button {
      border-radius: 25px;
    }

    .mat-mdc-snack-bar-action {
      color: $light-gray;
      border: 1px solid $light-gray;
      pointer-events: auto;
    }

    .mat-mdc-simple-snack-bar {
      margin: 5px 0;
    }

    /* Eyes-related snackbar styles */
    &.sso-snackbar-success,
    &.sso-snackbar-error,
    &.sso-snackbar-info {
      @extend .mat-elevation-z10;
      opacity: 1;
      border-radius: 5px;
      pointer-events: none;
    }

    &.sso-snackbar-success {
      background-color: $green;
    }

    &.sso-snackbar-error {
      background-color: $red;
    }

    &.sso-snackbar-info {
      background-color: $orange;
    }
  }
}

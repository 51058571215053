@mixin custom-mat-dialog-theme($theme) {
  .mat-mdc-dialog-container {
    overflow: hidden !important;
  }

  .mat-mdc-dialog-content {
    margin: 0 !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .mat-mdc-dialog-actions {
    padding-bottom: 25px !important;
  }
}

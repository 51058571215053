@import '@oculus/styling/ellipse/colors';

@mixin custom-mat-tree-theme($theme) {
  mat-tree.small-selection {
    .mat-icon {
      $icon-size: 18px;
      width: $icon-size;
      font-size: $icon-size;
      line-height: $icon-size;
      height: $icon-size;
      font-weight: bold;
      margin-left: -1em;
      color: #757575 !important;
      cursor: pointer;
    }

    .mat-tree-node {
      min-height: 28px !important;
    }
  }
}

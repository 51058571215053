/* You can add global styles to this file, and also import other style files */

@use '@angular/material' as mat;
@use '@ngneat/hot-toast/src/styles/styles.scss';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/themes/light-border.css';
@import 'styles/main.style';
@import 'styles/theming.style';
@import 'styles/dependencies/variables.style';
@import 'styles/typography.style';
@import '@ng-select/ng-select/themes/default.theme.css';

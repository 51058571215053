@mixin sso-widget-theme($theme) {
  .sso-widget-container-green {
    margin: 10px;
    padding: 10px;
    width: 120px;
    height: 120px;
    border-style: double;
    border-color: #69af23;
    border-radius: 10px;
    text-align: center;
    color: #69af23;
    font-size: x-small;
    h3 {
      font-weight: bolder !important;
      margin-bottom: 0px !important;
    }
    p {
      font-weight: bolder !important;
      line-height: 10px !important;
    }
  }

  .sso-widget-container-blue {
    margin: 10px;
    padding: 10px;
    width: 120px;
    height: 120px;
    font-size: smaller;
    border-style: double;
    border-color: #0b74c0;
    border-radius: 10px;
    text-align: center;
    color: #0b74c0;
    font-size: x-small;
    h3 {
      font-weight: bolder !important;
      margin-bottom: 0px !important;
    }
    p {
      font-weight: bolder !important;
      line-height: 10px !important;
    }
  }

  .sso-widget-container-red {
    margin: 10px;
    padding: 10px;
    width: 120px;
    height: 120px;
    border-style: double;
    border-color: #cc0033;
    border-radius: 10px;
    text-align: center;
    color: #cc0033;
    font-size: x-small;
    h3 {
      font-weight: bolder !important;
      margin-bottom: 0px !important;
    }
    p {
      font-weight: bolder !important;
      line-height: 10px !important;
    }
  }

  .sso-widget-container-yellow {
    margin: 10px;
    padding: 10px;
    width: 120px;
    height: 120px;
    border-style: double;
    border-color: #faca08;
    border-radius: 10px;
    text-align: center;
    color: #faca08;
    font-size: x-small;
    h3 {
      font-weight: bolder !important;
      margin-bottom: 0px !important;
    }
    p {
      font-weight: bolder !important;
      line-height: 10px !important;
    }
  }

  .sso-fluid-icon {
    font-weight: bold;
    position: relative;
    z-index: 1;
    display: inline-block;
    font-weight: 500;
    font-size: 5rem;
    font-family: 'iconfluid' !important;
    font-style: normal;
    font-variant: normal;
    line-height: 0.6;
    text-transform: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
  }

    .sso-fluid-icon-smaller {
    font-weight: bold;
    position: relative;
    z-index: 1;
    display: inline-block;
    font-weight: 500;
    font-size: 3rem;
    font-family: 'iconfluid' !important;
    font-style: normal;
    font-variant: normal;
    line-height: 0.6;
    text-transform: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
  }

  .sso-icon-green {
    font-weight: bold;
    position: relative;
    z-index: 1;
    color: #69af23;
    display: inline-block;
    font-weight: 400;
    font-size: 4rem;
    font-family: 'iconfluid' !important;
    font-style: normal;
    font-variant: normal;
    line-height: 0.6;
    text-transform: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
  }
  .sso-icon-blue {
    font-weight: bold;
    position: relative;
    z-index: 1;
    color: #0b74c0 !important;
    display: inline-block;
    font-weight: 400;
    font-size: 4rem;
    font-family: 'iconfluid' !important;
    font-style: normal;
    font-variant: normal;
    line-height: 0.6;
    text-transform: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
  }
  .sso-icon-red {
    font-weight: bold;
    position: relative;
    z-index: 1;
    color: #cc0033 !important;
    display: inline-block;
    font-weight: 400;
    font-size: 4rem;
    font-family: 'iconfluid' !important;
    font-style: normal;
    font-variant: normal;
    line-height: 0.6;
    text-transform: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
  }
  .sso-icon-yellow {
    font-weight: bold;
    position: relative;
    z-index: 1;
    color: #faca08 !important;
    display: inline-block;
    font-weight: 400;
    font-size: 4rem;
    font-family: 'iconfluid' !important;
    font-style: normal;
    font-variant: normal;
    line-height: 0.6;
    text-transform: none;
    vertical-align: middle;
    -webkit-font-smoothing: antialiased;
  }

  .percentage-red {
    color: #cc0033;
  }

  .percentage-gray {
    color: rgba(black, 0.54);
  }

  .percentage-green {
    color: #009934;
  }
}

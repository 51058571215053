@mixin sso-campaign-theme($theme) {
  .campaign-type-icon {
    $icon-size: 16px !important;
    height: $icon-size;
    width: $icon-size;
    font-size: $icon-size;
    line-height: $icon-size;

    margin-top: -10px;
  }
}

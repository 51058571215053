@use 'sass:map';
@use '@angular/material' as mat;

@import '@oculus/components/theming';

@import 'dependencies/palettes.style.scss';

@import 'components/custom/custom-mat-toolbar.theme.scss';
@import 'components/custom/custom-mat-snackbar.theme.scss';
@import 'components/custom/custom-mat-table.theme.scss';
@import 'components/custom/custom-mat-icon.theme.scss';
@import 'components/custom/custom-mat-button.theme.scss';
@import 'components/custom/custom-mat-form-field.theme.scss';
@import 'components/custom/custom-mat-dialog.theme.scss';
@import 'components/custom/custom-mat-select.theme.scss';
@import 'components/custom/custom-mat-expansion.theme.scss';
@import 'components/custom/custom-mat-tabs.theme.scss';
@import 'components/custom/custom-mat-progress-bar.theme.scss';
@import 'components/custom/custom-mat-stepper.theme.scss';
@import 'components/custom/custom-mat-tree.theme.scss';
@import 'components/custom/custom-mat-menu.theme.scss';
@import 'components/custom/custom-mat-list.theme.scss';
@import 'components/custom/custom-cdk-virtual-viewport.theme.scss';
@import 'components/custom/custom-ngx-slider.theme.scss';

@import 'components/general/general-scrollbar.theme.scss';
@import 'components/general/general-alert-message.theme.scss';
@import 'components/general/general-info-message.theme.scss';

@import 'components/ssotool/sso-header.theme.scss';
@import 'components/ssotool/sso-tooltip.theme.scss';
@import 'components/ssotool/sso-global.theme.scss';
@import 'components/ssotool/sso-widget.theme.scss';
@import 'components/ssotool/sso-charts.theme.scss';
@import 'components/ssotool/sso-efficiency-matrix.theme.scss';
@import 'components/ssotool/sso-execution-status.theme.scss';
@import 'components/ssotool/sso-yearly-input.theme.scss';
@import 'components/ssotool/sso-filters.theme.scss';
@import 'components/ssotool/sso-progress.theme.scss';
@import 'components/ssotool/sso-gantt.theme.scss';
@import 'components/ssotool/sso-kpi.theme.scss';
@import 'components/ssotool/sso-campaign.theme.scss';

@import 'components/ssotool/sso-breadcrumbs.theme.scss';

@import 'typography.style.scss';

@include mat.core();

$ssotool-primary: mat.define-palette($ssotool-primary-palette, 100, 500, 700);
$ssotool-accent: mat.define-palette($ssotool-accent-palette, 100, 500, 700);
$ssotool-warn: mat.define-palette($ssotool-warn-palette, 100, 500, 700);

$ssotool-theme: mat.define-light-theme(
  (
    color: (
      primary: $ssotool-primary,
      accent: $ssotool-accent,
      warn: $ssotool-warn,
    ),
  )
);

@include mat.all-component-themes($ssotool-theme);
@include mat.all-component-typographies($ssotool-typography);

@import 'dependencies/variables.style.scss';

/* sso */
@include sso-global-theme($ssotool-theme);
@include sso-header-theme($ssotool-theme);
@include sso-tooltip-theme($ssotool-theme);
@include sso-widget-theme($ssotool-theme);
@include sso-charts-theme($ssotool-theme);
@include sso-efficiency-matrix-theme($ssotool-theme);
@include sso-execution-status-theme($ssotool-theme);
@include sso-yearly-input-theme($ssotool-theme);
@include sso-filters-theme($ssotool-theme);
@include sso-progress-theme($ssotool-theme);
@include sso-breadcrumbs-theme($ssotool-theme);
@include sso-gantt-theme($ssotool-theme);
@include sso-kpi-theme($ssotool-theme);
@include sso-campaign-theme($ssotool-theme);

/* Custom */
@include custom-mat-button-theme($ssotool-theme);
@include custom-mat-toolbar-theme($ssotool-theme);
@include custom-mat-snackbar-theme($ssotool-theme);
@include custom-mat-table-theme($ssotool-theme);
@include custom-mat-icon-theme($ssotool-theme);
@include general-scrollbar-theme($ssotool-theme);
@include general-alert-message-theme($ssotool-theme);
@include general-info-message-theme($ssotool-theme);
@include custom-mat-form-field-theme($ssotool-theme);
@include custom-mat-dialog-theme($ssotool-theme);
@include custom-mat-select-theme($ssotool-theme);
@include custom-mat-expansion-theme($ssotool-theme);
@include custom-mat-tabs-theme($ssotool-theme);
@include custom-mat-progress-bar-theme($ssotool-theme);
@include custom-mat-stepper-theme($ssotool-theme);
@include custom-mat-tree-theme($ssotool-theme);
@include custom-mat-menu-theme($ssotool-theme);
@include custom-mat-list-theme($ssotool-theme);
@include custom-cdk-virtual-viewport-theme($ssotool-theme);
@include custom-ngx-slider-theme($ssotool-theme);

/* Oculus */
@include oculus-drawer-theme($ssotool-theme);
@include oculus-header-theme($ssotool-theme);
@include oculus-yearly-forecast-theme($ssotool-theme);

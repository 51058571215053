@import '@oculus/styling/ellipse/colors';

@mixin sso-header-theme($theme) {
  .sso-header {
    .sso-quick-links {
      mat-icon {
        color: $hint-color;
      }
    }

    .sso-quick-link-active,
    a[aria-expanded='true'] {
      @include ellipse-typography-gradient-font();
    }

    .sso-active-client {
      color: $hint-color;
      margin-right: 1rem !important;
    }
  }

  .sso-banner {
    color: #fff;
    background: $blue-allports;
    padding: 10px 20px;
    // border-radius: 30px;
  }
}

@mixin custom-mat-menu-theme($theme) {
  .mat-mdc-menu-item.warn-item {
    & > * {
      color: $red !important;
    }

    &:disabled > * {
      color: $disabled-text !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
  .mat-menu-half {
    max-height: 500px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of menu that may no longer apply for the MDC version. */
  .mat-menu-no-max.mat-mdc-menu-panel {
    max-width: none !important;
  }
}

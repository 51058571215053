@mixin sso-global-theme($theme) {
  .warning {
    color: $yellow;
  }

  .success {
    color: $green;
  }

  .failure {
    color: $red;
  }

  .sso-primary {
    color: $blue;
  }

  .sso-status-success {
    color: $green;
  }

  .sso-status-failed {
    color: $red;
  }

  .sso-status-draft {
    color: $gray;
  }

  .sso-status-pending {
    color: $yellow;
  }

  .sso-status-ongoing {
    color: $dark-blue;
  }
}

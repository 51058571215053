@mixin custom-cdk-virtual-viewport-theme($theme) {
  sso-log-dialog {
    .cdk-virtual-scroll-content-wrapper {
      position: relative !important;
    }
  }

  sso-gantt {
    cdk-virtual-scroll-viewport {
      display: block;
      position: relative;
      overflow: visible;
      contain: none;
    }
  }
}

@mixin sso-execution-status-theme($theme) {
  .exec-success {
    span.exec-status-text {
      color: $green !important;
      font-weight: bold;
    }

    .mat-badge-content {
      background-color: $green !important;
      font-family: 'Material Icons';
    }
  }

  .exec-failed {
    span.exec-status-text {
      color: $red !important;
      font-weight: bold;
    }

    .mat-badge-content {
      background-color: $red !important;
      font-family: 'Material Icons';
    }
  }

  .exec-processing {
    span.exec-status-text {
      color: $yellow !important;
      font-weight: bold;
    }

    .mat-badge-content {
      background-color: $yellow !important;
      font-family: 'Material Icons';
    }
  }

  .exec-cancelled {
    span.exec-status-text {
      color: $smoke-gray !important;
      font-weight: bold;
    }

    .mat-badge-content {
      background-color: $smoke-gray !important;
      font-family: 'Material Icons';
    }
  }

  .exec-outdated {
    span.exec-status-text {
      color: $black !important;
      font-weight: bold;
    }

    .mat-badge-content {
      background-color: transparent;
      color: transparent;
      font-family: 'Material Icons';
    }
  }

  .exec-draft {
    span.exec-status-text {
      color: $smoke-gray !important;
      font-weight: bold;
    }

    .mat-badge-content {
      background-color: transparent;
      color: transparent;
      font-family: 'Material Icons';
    }
  }

  .exec-retrying {
    span.exec-status-text {
      color: $dark-shade-blue !important;
      font-weight: bold;
    }

    .mat-badge-content {
      background-color: transparent;
      color: transparent;
      font-family: 'Material Icons';
    }
  }
}

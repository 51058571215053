@import '@oculus/styling/ellipse/colors';

@mixin custom-mat-stepper-theme($theme) {
  mat-vertical-stepper.content-only {
    .mat-step-header {
      white-space: nowrap;
      display: none !important;
      align-items: center;
    }

    .mat-vertical-content-container {
      &::before {
        content: none !important;
      }
    }
  }

  mat-vertical-stepper.header-only {
    .mat-step-text-label {
      white-space: initial !important;
    }

    .mat-vertical-content-container {
      display: none !important;
    }
  }

  mat-vertical-stepper.custom-full-height {
    flex-direction: column !important;
    box-sizing: border-box !important;
    display: flex !important;
    place-content: flex-start space-between !important;
    align-items: flex-start !important;
    position: relative;

    &:after {
      z-index: 0;
      content: '';
      position: absolute;
      display: block;
      height: calc(100% - 40px);
      border: 2px solid map-get($grays, 300);
      left: 39px;
      width: 0;
      border-width: 0 2px 0 0;
      transition: ease all 0.5s;
      margin: 20px 0px;
    }

    .mat-stepper-vertical-line {
      display: none !important;
    }

    .mat-step-label.mat-step-label-selected {
      font-weight: bold !important;
    }

    .mat-step-icon {
      height: 32px !important;
      width: 32px !important;
      z-index: 1;

      &.mat-step-icon-state-number {
        background-color: map-get($grays, 300) !important;

        .mat-step-icon-content {
          font-size: 16px !important;
          color: map-get($grays, 800) !important;
        }

        &.mat-step-icon-selected {
          background-color: white !important;
          border: 2px solid $blue-corporate !important;
          margin-left: -2px !important;

          .mat-step-icon-content {
            font-size: 16px !important;
            color: $blue-corporate !important;
          }
        }
      }
    }
  }
}

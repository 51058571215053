@mixin general-alert-message-theme($theme) {
    .warning-message {
        text-align: center;
        padding: 10px;
        width: 100%;
        background-color: rgba($orange, 0.075);

        mat-icon {
            color: $orange;
        }
    }
}
